.contact-main {
  width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 
}
.contact-main form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    padding: 20px;
}


.contact-main form input{
    width: 65%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}
.contact-main form textarea{
    width: 65%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.contact-main p{
    word-spacing: normal;
    margin-bottom: 20px;

}

.contact-info{
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    padding: 20px;
}

.contact-info h1{
    margin-bottom: 10%;
}
.contact-info img{
    width: 50%;
    margin-bottom: 10%;
}
.form-note{
  text-align: justify;
}
@media screen and (max-width:600px) {
    .contact-main{
        margin-top: 10%;
        width: 90%;
    }
    .contact-main form input{
        width: 120%;
    }
    .contact-main form textarea{
        width: 120%;
    }
    .contact-info{
        width: 90%;
    }
    .contact-info img{
        width: 90%;
    }
  
}