.flexhome{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}
.free-session-mobile{
    display: none;

}
.main-home p{
    font-weight: 400;
    margin-bottom: 15%;
    margin-top: -10%;
    text-align: center;
    width: 100%;
}
.header-text-desktop{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    justify-content: center;
    color: #004aad;
    align-items: center;
    width: 65%;
    margin-bottom: 15%;
}
.header-text-mobile{
    display: none;
}
.free-session{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1%;
}

.free-session-desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 2%;
    padding-top: 5%;
}
.free-session-desktop h6{
    margin-bottom: 5%;
    text-align: center;
    padding-right: 5%;
  
}

.main-home{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.logo-min{
    display: none;
}
.home-min{
    display: none;
}
.header-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
   
}
.button-home{
    display: flex;
    flex-direction: row;
 justify-content: center;
    align-items: center;
 margin-right: 5%;
    width: 100%
 

   
}
.home-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    
}

.logo-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
   
   
}

.button-about-us, .button-get-in{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    width: 30%

}
.button-about-us:hover, .button-get-in:hover{
    background-color: #F2F2F2;
    cursor: pointer;
}
.button-about-us a, .button-get-in a{
    text-decoration: none;
    color: black;
}
.button-about-us a:hover, .button-get-in a:hover{
    color: royalblue;
}

.free-session-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    background-color: royalblue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    justify-content: center;
    align-items: center;
    margin-right: 10%;
    margin-bottom: 10%;
    width: 65%;

}

.free-session-btn a{
    text-decoration: none;
    color: white;
}
.free-session-btn:hover{
    background-color: green;
    cursor: pointer;
}


.footer-home{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #F2F2F2;
    padding-top: 1%;
    padding-bottom: 1%;

}
.footer-home img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3%;
   margin: 0 1%;
}

.footer-home a{
    text-decoration: none;
    color: black; 
    font-weight: bold;  
}
.footer-home a:hover{
    color: royalblue;
}
@media screen and (max-width:600px) {
    .main-home{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .main-home p{
        font-size: xx-small;
        font-weight: 400;
        /* margin-right: 35px; */
        margin-bottom: 10%;
        margin-top: -5%;
        text-align: center;
        width: 100%;
    }
    .header-home{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    .button-home{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .home-min{
        display: flex;
        flex-direction: column;
        justify-content: center;
       margin-left: -8%;
      
        align-items: center;
        width: 100vw;
     
    }
    .logo-min{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 31vw;
    }

    .logo-home{
        display: none;
    }
    .home-img{
        display: none;
    }

    .free-session-mobile{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

       
    }

    .free-session-desktop{
        display: none;
    }
    .header-text-mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #004aad;
        font-weight: 400;
        width: 100%;
        /* margin-top: 10%; */
        margin-bottom: 15%;
        padding-right: 5%;
    }

    .header-text-desktop{
        display: none;
    }
.button-about-us, .button-get-in{
    margin-bottom: 5%;
    width: 40%;
}
  .free-session-btn{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: royalblue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    margin-top: 8%;
    width: 75%;}

    .free-session-btn a{
        text-decoration: none;
        color: white;
    }
    .footer-home img{
        width: 6%;
    }
}