.burger-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: 20px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
  }
  
  .burger-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .open .bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
  }
  
  .menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    width: 300px;
    height: 100vh;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .open .menu {
    display: block;
  }
  .menu a {
    text-decoration: none;
    color: #333;
  }
  .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }
  

  @media screen and (max-width: 600px) {
    .bar1,
    .bar2,
    .bar3 {
      width: 25px;
      height: 3px;
      margin: 3px 0;
    }
  }