.services{
   
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.services p{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: normal;
    margin-bottom: 20px;

}

.service p, h3{
    width: 75%;
    margin: 0 auto;
    padding: 20px;
}

@media screen and (max-width:600px) {
    .services{
        margin-top: 10%;
        width: 90%;
    }
    .services p{
        width: 100%;
    }
    .service p, h3{
        width: 100%;
    }

    .service img{
        width: 90%;
    }
    
}
    
