.about{
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about h1{
    text-align: center;
    margin-bottom: 20px;
}
.about h5{
    text-align: center;
    margin-bottom: 20px;
}
.about p{
    width: 75%;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-word;
    word-spacing: normal;
    margin-bottom: 20px;

}



.about img{
    width: 50%;
    margin-bottom: 5%;
}

@media screen and (max-width:600px) {
    .about{
        margin-top: 10%;
        width: 90%;
    }
    .about p{
        width: 100%;
    }
    .about img{
        width: 90%;
    }
    
}